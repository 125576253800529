<template>
    <div>
        <v-row>
            <v-col cols="12">
                <div class="d-flex justify-space-between">
                    <div style="padding: 18px 12px">
                        <div class="text-2xl"><i :class="$route.params.icon"></i> {{ $route.params.title }}</div>
                    </div>
                    <v-breadcrumbs
                        class="d-none d-sm-block d-md-block d-lg-block d-xl-block"
                        :items="$route.meta.breadcrumb"
                        divider=">"
                        large
                    />
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-text>
                        <div>
                            <v-alert
                                color="blue-grey lighten-4"
                                shaped
                                dense
                                class="text-center"
                            >
                                <strong>ລາຍການຮັບພັດສະດຸລົງສາງ</strong>
                            </v-alert>
                        </div>
                        <v-form v-model="addDelivertValid" ref="formAddPackageData" lazy-validation @submit.prevent="addMorePackage()">
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field 
                                        type="text" 
                                        label="ເລກທີຕິດຕາມພັດສະດຸ" 
                                        outlined  
                                        dense
                                        :rules="[v => !!v || 'ກະລຸນາປ້ອນຂໍ້ມູນກ່ອນ']"
                                        required 
                                        rounded
                                        append-icon="mdi-barcode-scan"
                                        v-model="formdata.track_code"
                                        ref="trackcode"
                                    />
                                </v-col>
                            </v-row>
                        </v-form>
                        <v-row>
                            <v-col cols="12">
                                <v-data-table
                                    :headers="packageField"
                                    :items="listPackage"
                                    :search="search"
                                    :items-per-page="5"
                                    class="elevation-1"
                                    :loading="$store.state.auth.loading2"
                                    loading-text="ກຳລັງໂຫລດ...ກະລຸນາລໍຖ້າ"
                                    item-key="br_id"
                                    v-model="selected"
                                    fixed-header
                                    height="300px"
                                    dense
                                    :footer-props="{
                                        'items-per-page-options': [5, 10, 20, 100, -1],
                                        showFirstLastPage: true,
                                        'items-per-page-text': 'ຈຳນວນທີ່ສະແດງ:'
                                }"
                                >
                                    <template v-slot:item.option="{ item }">
                                        <div class="whitespace-nowrap">
                                            <v-btn
                                                class="mr-1"
                                                color="error"
                                                fab
                                                small
                                                dark
                                                text 
                                                @click="deleteData(item)"
                                            >
                                                <v-icon>mdi-delete</v-icon>
                                            </v-btn>
                                        </div>
                                    </template>
                                    <template v-slot:item.pack_amount="{ item }">
                                        {{ $helper.formatNumber(item.pack_amount, 0) }}
                                    </template>
                                    <template v-slot:item.pack_price="{ item }">
                                        <div class="whitespace-nowrap">
                                        {{ $helper.formatNumber(item.pack_price, 0) }}
                                        </div>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-row>
                            <v-col cols="12" class="text-right">
                                <v-btn class="mx-1" color="success" @click="saveData()"><i class="mdi mdi-content-save-outline"></i> ບັນທຶກ</v-btn>
                                <v-btn class="mx-1" color="error" @click="$router.push({ name: 'delivery' })"><i class="mdi mdi-close-circle-outline"></i> ກັບຄືນ</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                addDelivertValid: false,
                packageField: [
                    {
                        text: 'ຕົວເລືອກ',
                        align: 'center',
                        sortable: false,
                        value: 'option',
                        class: 'black--text title amber lighten-1 black--text whitespace-nowrap'
                    },
                    {
                        text: '#',
                        align: 'center',
                        sortable: false,
                        value: 'rno',
                        class: 'black--text title amber lighten-1 black--text whitespace-nowrap'
                    },
                    {
                        text: 'ເລກທີຕິດຕາມພັດສະດຸ',
                        align: 'center',
                        sortable: false,
                        value: 'track_code',
                        class: 'black--text title amber lighten-1 black--text whitespace-nowrap'
                    },
                    {
                        text: 'ຊື່ພັດສະດຸ',
                        align: 'start',
                        sortable: false,
                        value: 'pack_name',
                        class: 'black--text title amber lighten-1 black--text whitespace-nowrap'
                    },
                    {
                        text: 'ຈຳນວນ',
                        align: 'center',
                        sortable: false,
                        value: 'pack_amount',
                        class: 'black--text title amber lighten-1 black--text whitespace-nowrap'
                    },
                    {
                        text: 'ລາຄາ',
                        align: 'end',
                        sortable: false,
                        value: 'pack_price',
                        class: 'black--text title amber lighten-1 black--text whitespace-nowrap'
                    },
                    {
                        text: 'ລາຍລະອຽດ',
                        align: 'start',
                        sortable: false,
                        value: 'pack_remark',
                        class: 'black--text title amber lighten-1 black--text col-2 whitespace-nowrap'
                    },
                ],
                listPackage: [],
                search: '',
                selected: '',
                formdata: {
                    track_code: '',
                }
            }
        },   
        async created() {

        },
        methods: {
            async addMorePackage(){
                var checkdupplicatetrackcode = 0;
                for (const [i, rows] of Object.entries(this.listPackage)) {
                    if(rows.track_code == this.formdata.track_code){
                        checkdupplicatetrackcode++;
                    }
                }
                if(checkdupplicatetrackcode == 0){
                    const formdata = await {
                        action: "selectpackageforinstock",
                        code: this.formdata.track_code,
                    }
                    const result = await this.$store.dispatch("params/getListSelectBox",formdata);
                    if(result.success){
                        const obj = await {
                            rno: 0,
                            track_code: result.data.track_code,
                            pack_name: result.data.pack_name,
                            pack_amount: result.data.pack_amount,
                            pack_price: result.data.pack_price,
                            pack_ccy: result.data.pack_ccy,
                            pack_remark: result.data.pack_remark,
                        }
                        this.listPackage.push(obj);
                        for (const [i, rows] of Object.entries(this.listPackage)) {
                            rows.rno = await Number.parseInt(i) + 1;
                        }

                        this.formdata.track_code = await "";
                        await this.$refs.trackcode.focus();
                    }else{
                        this.$swal.fire("Oops", result.message, "warning");
                    }
                }else{
                    this.$swal.fire("Oops", "ພັດສະດຸເລກທີ " + this.formdata.track_code + " ມີໃນຕາຕະລາງແລ້ວ", "warning");
                    this.formdata.track_code = await "";
                    await this.$refs.trackcode.focus();
                }
            },
            async deleteData(data){
                this.$swal.fire({
                    title: 'ລຶບຂໍ້ມູນ',
                    text: 'ທ່ານຕ້ອງການລຶບຂໍ້ມູນ ຫລື ບໍ່ ?',
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonColor: '#85ce61',
                    cancelButtonColor: '#F56C6C',
                    confirmButtonText: '<i class="mdi mdi-check-circle-outline"></i>  ຕົກລົງ',
                    cancelButtonText: '<i class="mdi mdi-close-circle-outline"></i>  ຍົກເລີກ'
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        var isindex = 0;
                        for (const [i, rows] of Object.entries(this.listPackage)) {
                            if(rows.track_code == data.track_code){
                                isindex = i;
                            }
                        }
                        await this.listPackage.splice(isindex, 1);
                        for (const [i, rows] of Object.entries(this.listPackage)) {
                            rows.rno = await Number.parseInt(i) + 1;
                        }
                    }
                });
            },
            async saveData(){
                if(this.listPackage.length == 0){
                    this.$swal.fire({
                        title: 'Oops',
                        text: 'ກະລຸນາປ້ອນຂໍ້ມູນພັດສະດຸກ່ອນ',
                        icon: 'warning',
                    })
                }else{
                    this.$swal.fire({
                        title: 'ບັນທຶກຂໍ້ມູນ',
                        text: 'ທ່ານຕ້ອງການບັນທຶກຂໍ້ມູນ ຫລື ບໍ່ ?',
                        icon: 'question',
                        showCancelButton: true,
                        confirmButtonColor: '#85ce61',
                        cancelButtonColor: '#F56C6C',
                        confirmButtonText: '<i class="mdi mdi-check-circle-outline"></i>  ຕົກລົງ',
                        cancelButtonText: '<i class="mdi mdi-close-circle-outline"></i>  ຍົກເລີກ'
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            const formdata = await {
                                action: 'addpackageinstock',
                                listpackage: this.listPackage,
                            }
                            await this.$store.commit("auth/setLoading", true);
                            const result = await this.$store.dispatch("manage/getManagePackage", formdata);
                            await this.$store.commit("auth/setLoading", false);
                            if(result.success){
                                this.$toast.fire(result.message,"","success");
                                await this.$router.push({name: 'wharehouse'});
                            }else{
                                this.$swal.fire("Oops",result.message,"warning");
                            }
                        }
                    });
                }
            }
            
        },

    }
</script>